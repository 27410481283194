import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './fonts.scss';
import Reader from "@imperium-polskiego-hentaju/scarlet-reader";
import VueSimpleAlert from 'vue-simple-alert';
import vueMoment from 'vue-moment';

Vue.use(VueSimpleAlert);
Vue.use(vueMoment);
Vue.use(Reader);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
