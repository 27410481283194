<template>
<div>
    <b-container fluid="md" class="bigcontainer">
        <b-row>
            <b-col sm="9" class="bigcontainer">
                <div v-for="(mangas, index) in mangas" :key="index" class="galcon">
                    <router-link :to="'/reader/' + mangas.number">
                        <img :src="'https://hentaihaven.pl/manga/' + mangas.number + '/thumb.jpg'" alt="kek">
                    </router-link>
                    <div class="insides">
                        <router-link :to="'/search/' + mangas.tags[0]">{{mangas.tags[0]}}</router-link>
                        <router-link :to="'/search/' + mangas.tags[1]">{{mangas.tags[1]}}</router-link>
                        <router-link :to="'/search/' + mangas.tags[2]">{{mangas.tags[2]}}</router-link>
                        <router-link class='h1' :to="'/reader/' + mangas.number">{{mangas.title}}</router-link>
                    </div>
                 </div>
            </b-col>
            <b-col sm="3" class="twatter">
              <a class="twitter-timeline" data-height="95%" data-dnt="true" data-theme="dark" href="https://twitter.com/HentaiHavenPL?ref_src=twsrc%5Etfw">Tweets by Izabela1337</a>
            </b-col>
        </b-row>
    </b-container>
    <chr>..</chr>
    <b-container fluid="md" class="bigcontainerstr">
        <b-row>
            <b-col lg="4" class="secondrow">
                <b-container class="dic">
                    <h1>Tag na dziś!</h1>
                    <b-container class="text-white" style="max-width:90%">
                        <h3 style="text-decoration: underline;">{{ dics.name }}</h3>
                        <p><i>{{ dics.description }}</i></p>
                        <b-button :to="'/search/' + dics.name">Zobacz mangi z tagiem <b-badge>{{dics.name}}</b-badge></b-button>
                    </b-container>
                </b-container>
            </b-col>
            <b-col lg="4" class="secondrow">
                <div>
                <h1>Już niedługo</h1>
                <div class="bigcontainer">
                    <div v-for="(incomming, index) in incomming" :key="index" class="galconinc">
                        <a href="#">
                            <img :src="'images/oczekiwanko/' + index +'.jpg'" alt="kek">
                        </a>
                        <div class="insidesinc">
                            <h4>{{ incomming }}</h4>
                        </div>
                    </div>
                </div>
                </div>
            </b-col>
            <b-col lg="4" class="secondrow">
                <b-container >
                    <div class="dic2">
                        <h1>Co nowego:</h1>
                        <b-list-group class="text-left">
                            <b-list-group-item v-for="(updates, index) in updates" :key="index">{{updates.title}} <b-badge>{{updates.date | moment('D.M')}}</b-badge></b-list-group-item>
                        </b-list-group>
                    </div>
                </b-container>
            </b-col>
        </b-row>
    </b-container>
    <chr>..</chr>
    <b-container fluid="md" class="bigcontainer">
        <h1>Kącik tłumaczeń Halnego</h1>
        <div class="bigcontainer">
            <div v-for="(mangahalny, index) in mangahalny" :key="index" class="galcon">
                <router-link :to="'/reader/' + mangahalny.number">
                    <img :src="'https://hentaihaven.pl/manga/' + mangahalny.number + '/thumb.jpg'" alt="kek">
                </router-link>
                <div class="insides">
                    <router-link :to="'/search/' + mangahalny.tags[0]">{{mangahalny.tags[0]}}</router-link>
                    <router-link :to="'/search/' + mangahalny.tags[1]">{{mangahalny.tags[1]}}</router-link>
                    <router-link :to="'/search/' + mangahalny.tags[2]">{{mangahalny.tags[2]}}</router-link>
                    <router-link class='h1' :to="'/reader/' + mangahalny.number">{{mangahalny.title}}</router-link>
                </div>
            </div>
        </div>
    </b-container>
    <chr>..</chr>
</div>
</template>
<script>
import MangasModule from "@/backend/mangasmodule.js";
import chr from "@/components/chr.vue";

export default{
    name: "mangas",
    data(){
        return{
            mangas: [],
            mangahalny: [],
            incomming: '',
            dics: [],
            updates: []
        };
    },
    methods:{
        /* eslint-disable no-console */
        fetchMangasFeatured(){
            MangasModule
                .get("/GetFeaturedMangas")
                .then(response => {
                    this.mangas = response.data;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        fetchMangahalny(){
            MangasModule
                .get("/GetHalnyMangas")
                .then(response => {
                    this.mangahalny = response.data;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        fetchIncomming(){
            MangasModule
            .get("/GetIncomming")
            .then(response => {
                this.incomming = response.data;
            }).catch(err => {
                console.log(err)
            });
        },
        fetchDics(){
            MangasModule
            .get("/GetRandomTag")
            .then(res => {
                this.dics = res.data;
            }).catch(err => {
                console.log(err)
            });
        },
        fetchUpdates(){
            MangasModule
            .get("/GetUpdates")
            .then(res => {
                this.updates = res.data;
            }).catch(err => {
                console.log(err)
            });
        }
    },
    refreshList(){
        this.fetchMangasFeatured();
        this.fetchMangahalny();
        this.fetchIncomming();
        this.fetchDics();
    },
    /* eslint-enable no-console */
    components:{
        chr
    },
    mounted(){
        let beniz = document.createElement('script');    
        beniz.setAttribute('src',"https://platform.twitter.com/widgets.js");
        document.head.appendChild(beniz);
        this.fetchMangasFeatured();
        this.fetchMangahalny();
        this.fetchDics();
        this.fetchIncomming();
        this.fetchUpdates();
    }
}
</script>
<style lang="scss" scoped>
.bigcontainer{
    align-items: center;
    display: flex;
    background-color: rgb(62,21,37);
    width: 100%;
    padding: 1vh 0 1vh 0;
    text-align: center;
    margin: 0;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    max-width: 100%;
}
.bigcontainerstr{
    background-color: rgb(62,21,37);
    align-items: center;
    text-align: center;
    max-width: 100%;
    padding: 1vh 0 1vh 0;
}
.row{
    max-width: 100%;
}
.bigcontainer a{
    color: rgb(195, 34, 96);
}
.galcon{
    position: relative;
    width: 18%;
    height: auto;
    margin: 5px;
    transition: 0.5s;
}
.galcon img{
    width: 100%;
    height: auto;
    z-index: -1;
}
.insides{
    width: 100%;
    max-height: 60%;
    position: absolute;
    bottom: 0;
    background-color: rgba(85, 15, 42, 0.9);
    padding: 6px 0 0 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.insides a{
    padding: 3px;
    color: black;
    background-color: rgb(195, 34, 96);
    border-radius: 5px;
    overflow: hidden;
    text-decoration: none;
    margin: 1%;
    line-height: 2;
    font-size: 10pt;
}
.insides .h1{
    font-family: 'Rubik', sans-serif;
    font-size: 1.5em;
    color: white;
    padding: 10px 0 10px 0;
    white-space: normal;
    display: block;
    background-color: rgba($color: #000000, $alpha: 0);
    line-height: 1;
}
.galcon:hover{
    border: -20px solid rgba(195, 34, 96, 0.25);
    filter: contrast(120%);
}
h1{
    color: white;
    font-family: 'Josefin Sans', sans-serif;
}
.row{
    margin: 0;
    padding: 0;
}
.dic{
    text-align: center;
    align-self: center;
    background-color: rgb(195, 34, 96);
    border: 1px solid black;
    border-radius: 5px;
    padding:10px;
    margin: auto;
}
.dic2 {
    padding-top:10px;
    background-color: rgb(195, 34, 96);
    border-radius: 10px;
    text-align: center;
    align-self: center;
}
.secondrow{
    display: grid;
    place-items: center;
    margin-bottom:2vh
}
.galconinc{
    position: relative;
    width: 30%;
    height: auto;
    margin: 5px;
    color: white;
    font-family: 'Quicksand', sans-serif;
    transition: transform .3s ease-in;
    text-align: center;
}
.galconinc:hover{
    transform: scale(1.1);
    z-index: 99;
}
.insidesinc{
    height: 100%;
    width: 100%;
    background-color: rgba(85, 15, 42, 0.8);
    position: absolute;
    bottom: 0;
    overflow: hidden;
    padding-top:5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.insidesinc h4{
    vertical-align: middle;
}
.galconinc img{
    width: 100%;
    height: auto;
    z-index: -1;
}
//responsive
@media only screen and (max-width: 1366px){
    .galconinc{
        width: 42%;
    }
}
@media only screen and (max-width: 997px){
    .galcon{
        width: 30%;
        margin: 6px 0;
        margin: 5px;
    }
}
@media only screen and (max-width: 575px) {
    .galcon{
        width: 46%;
    }
    .twatter {
    min-height: 65vh !important;
}
}
</style>