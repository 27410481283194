import authService from '@/services/auth.Service.js';

const user = localStorage.getItem("user");
const iniState = user
    ? {status: { loggedIn: true }, user}
    : {status: { loggedIn: false }, user: null}

export const auth = {
    namespaced: true,
    state: iniState,
    actions: {
        login({ commit }, user) {
            return authService.login(user).then(user =>{
                commit('loginSuccess', user);
                return Promise.resolve(user);
            },
            error => {
                commit('loginFailure');
                return Promise.reject(error);
            }
            );
        },
        logout({ commit }) {
            authService.logout();
            commit('logout');
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
    }
};