import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueAnalytics from 'vue-analytics'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Główna - HentaiHaven.pl'
    }
  },
  {
    path: '/contact',
    name: 'Kontakt - HentaiHaven.pl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: {
      title: 'Kontakt - HentaiHaven.pl'
    }
  },
  {
    path: '/reader/:id',
    name: 'Reader',
    component: () => import('../views/Reader.vue'),
    meta: {
      title: 'Reader - HentaiHaven.pl'
    }
  },
  {
    path: '/manga/:page?',
    name: 'manga',
    component: () => import('../views/Manga.vue'),
    meta: {
      title: 'Wszystkie tytuły - HentaiHaven.pl'
    }
  },
  {
    path: '/search/:query?',
    name: 'Wyszukiwanie',
    component: () => import('../views/Search.vue'),
    meta: {
      title: 'Szukaj - HentaiHaven.pl'
    }
  },
  {
    path: '/DICtionary',
    name: 'Slowniczek',
    component: () => import('../views/Dictionary.vue'),
    meta: {
      title: 'Słowniczek - HentaiHaven.pl'
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/Blog.vue'),
    meta: {
      title: 'Blog - HentaiHaven.pl'
    }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue'),
    meta: {
      title: 'Często Zadawane Pytania - HentaiHaven.pl'
    }
  },
  {
    path: '/privacy',
    name: 'Polityka Prywatności',
    component: () => import('../views/Privacy.vue'),
    meta: {
      title: 'Polityka Prywatności - HentaiHaven.pl'
    }
  },
  //admin pages
  {
    path: '/totallynotadmin/panels/Content',
    name: 'PanelAddMod',
    component: () => import ('../views/admin/ContentPanel.vue'),
    meta: {
      title: "ADMIN - Content Panel - HentaiHaven.pl",
    }
  },
  {
    path: '/totallynotadmin/panels/Logs',
    name: "PanelOverview",
    component: () => import('../views/admin/LogsPanel.vue'),
    meta: {
      title : "ADMIN - Logs Panel - HentaiHaven.pl",
    }
  },
  {
    path: '/totallynotadmin/login',
    name: 'PanelLogin&Register',
    component: () => import ('../views/admin/Login.vue'),
    meta: {
      title:  "Zaloguj się bałwanie!"
    }
  },
  //err pages
  {
    path: '*',
    name: '404 - strony nie znaleziono',
    component: () => import('../views/err/404.vue'),
    meta: {
      title: 'Strony nie znaleziono - HentaiHaven.pl'
    }
  },
  {
    path: '/500',
    name: 'Broke!',
    component: () => import('../views/err/500.vue'),
    meta: {
      title: 'Zepsułeś! Zepsułeś! Zepsułeś!'
    }
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
  return { x: 0, y: 0 }
  }
})

Vue.use(VueAnalytics, {
  id: 'UA-161555192-1',
  disabled: () => {
    if (localStorage.deniedTracking == "true" || localStorage.deniedTracking == undefined){
      return true
    } else
      return false
  },
  router
});

export default router
