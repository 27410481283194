<template>
  <div class="home">
    <indexlist>..</indexlist>
  </div>
</template>

<script>
import indexlist from '@/components/indexlist.vue';

export default {
  name: 'Główna',
  components: {
    indexlist
  }
}
</script>
