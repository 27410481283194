import axios from 'axios';
import headerService from '@/services/header.Service.js';

const baseURL = "https://hentaihaven.pl/api/"

class AuthService {
    login(user){
        return axios
            .post(baseURL + 'Login', {
                userName: user.username,
                password: user.passwd
            })
            .then(response => {
                if (response.data.tokenStr){
                    localStorage.setItem('user', response.data.tokenStr);
                } else{
                    localStorage.setItem('bugReport', response.data);
                }
                return response.data;
            });
    }
    validateToken(){
        return axios
            .get(baseURL + 'getUser',{headers: headerService.attachTokenHeader()})
            .then(response =>{
                return response.status;
            }).catch(err => {
                console.log(err)
                return null
            })
    }
    refreshToken(){
        return axios
            .get(baseURL + 'RefreshToken', {headers: headerService.attachTokenHeader()})
            .then(response => {
                localStorage.setItem('user', response.data.tokenStr);
            }).catch(err => {
                console.log('Wystąpił problem z autoryzacją, błąd to ' + err)
                this.logout()
            })
    }
    logout(){
        axios.get(baseURL + 'Logout',{headers: headerService.attachTokenHeader()}).catch(err => {
            console.log(err)
        })
        localStorage.removeItem('user')
    }
    register(user){
        return axios
        .post(baseURL + 'Register',{
            userName: user.username,
            email: user.email,
            password: user.password
        } )
    }
}

export default new AuthService();