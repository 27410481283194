<template>
     <div class="feetarentsexy">
        <h4>Wykonane z <i><b-icon-heart-fill/> Miłością <b-icon-heart-fill/></i> przez team HentaiHaven.pl</h4>
        <h6>Wszystkie postacie przedstawione na tej stronie są pełnoletnie, nawet jeśli jest powiedziane inaczej.</h6>
        <router-link to="/contact" class="text-dark"><u>Kontakt/</u></router-link>
        <a href="https://discord.com/invite/EdqQaGB" class="text-dark"><u>Discord Invite</u></a>
    </div>
</template>
<script>
import {BIconHeartFill} from 'bootstrap-vue'
export default {
    components:{
        BIconHeartFill
    }
}
</script>
<style lang="scss" scoped>
.feetarentsexy{
    position: relative;
    width: 100%;
    color: black;
    text-align: center;
    font-family: 'quicksand', sans-serif;
    background-image: url("/public/images/topbanner.png");
    background-size: cover;
    font-size: 20pt;
    padding-top:1vh;
    right: 0;
    bottom: 0;
}
</style>