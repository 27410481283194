<template>
    <div class="container-fluid logos bg-primary">
         <router-link to="/">
             <img class="mx-auto d-block img-fluid" style="padding: 1vh 0 1vh 0;" src="/images/logo3.png">
         </router-link>
    <b-navbar toggleable="md" type="dark" class="justify-content-between cusdomnavbar">
       <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item to="/" exact>Główna</b-nav-item>
                <b-nav-item to="/manga">Manga</b-nav-item>
                <b-nav-item-dropdown text="Wiki">
                    <b-dropdown-item to="/DICtionary">Słowniczek</b-dropdown-item>
                    <b-dropdown-item to="/FAQ">FAQ o HentaiHaven.pl</b-dropdown-item>
                    <b-dropdown-item to="/Blog">Hentajowy Blog</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
                <b-toast id="tooShort" title="Masz za małego" variant="danger">Twój wyszukiwań jest za mały.</b-toast>
                <b-toast id="tooLong" title="Masz za dużego" variant="danger">Twój wyszukiwań jest za duży.</b-toast>
                <b-form-input size="md" class="mr-sm-2" placeholder="tag, tytuł, autor" v-model="tagse" v-on:keyup.enter="SearchBox"></b-form-input>
                <b-button size="md" class="btn-dark my-2 my-sm-0" type="submit" v-on:click="SearchBox">Wyszukaj</b-button>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
        <div>
            <b-modal id="cookiesAlert" centered hide-footer @hidden="enableTracking" title="Pliki Cookie">
                <p class="my-4">Używamy plików cookie by szpiegować na was i sprzedawać wasze dane chińczykom. Z legalnych przyczyn muszę powiedzieć, że to żart. Akceptując lub zamykając ten komunikat zgadzacie się na używanie plików cookie. Więcej informacji znajdziecie na <router-link to="/privacy" style="color: black!important"><b>stronie naszej polityki prywatności.</b></router-link></p>
                <b-button class="mt-3 buttonAccept" block @click="enableTracking">spomko</b-button>
                <b-button class="mt-3 buttonRefuse" block @click="disableTracking">nie dziemkuje</b-button>
            </b-modal>
        </div>
    </div>
        
</template>
<script>
import {Event} from '@/bus.js'

export default {
    name: "Navbar",
    data(){
        return{
            query: '',
            tagse: ''
        };
    },
    methods: {
        SearchBox(){
            var loquery = this.tagse;
            if (loquery.length > 2 && loquery.length < 64){
                this.$router.push("/search/" + loquery);
                Event.$emit('NewSearch', 'penis');
            } else if(loquery.length < 3) {
                this.$bvToast.show('tooShort')
            } else {
                this.$bvToast.show('tooLong')
            }
        },
        disableTracking(){
            localStorage.deniedTracking = true
            this.$ga.disable()
            this.$bvModal.hide('cookiesAlert')
            console.log("cookies should be now disabled")
        },
        enableTracking(){
            if (localStorage.deniedTracking != "true"){
                localStorage.deniedTracking = false
                this.$ga.enable()
            }
            this.$bvModal.hide('cookiesAlert')
        }
    },
    mounted(){
        if(localStorage.confirmedAge == 1){
            console.log('Ya sneaky bastard')
        } else {
            this.$fire({
            backdrop: `rgba(14,14,14,0.8)`,
            title: 'Siostrzyczko, braciszku! <br> Czy jesteście pełnoletni?',
            showCancelButton: true,
            confirmButtonText: 'Pewnie, pokaż mi lolitki!',
            cancelButtonText: 'Nie, już uciekam!',
            confirmButtonColor: '#7d0734'
        }).then(r => {
            if (r.value == true){
                localStorage.confirmedAge = 1;
                this.$bvModal.show("cookiesAlert")
            } else {
                this.$fire({
                    title: 'B-Baka! Nie możesz tu wejść!',
                    imageUrl: 'https://media1.tenor.com/images/e293a6ac4617d33c1b0618dd470314fc/tenor.gif'
                })
                setTimeout(() => {
                    window.location.href = "https://www.pajacyk.pl";
                }, 2000);
            }
        })
        }
    }
}
</script>
<style lang="scss">
.my-4.router-link-active{
    color: black;
}
.buttonAccept{
    background-color: rgb(195, 34, 96);
}
.buttonRefuse{
    background-color: rgba(17,17,17,1);
}
.modal-title{
    font-family: "Rubik", sans-serif;
    color: black;
    font-weight: 600;
}
.modal-dialog-centered {
    align-items: flex-end;
}
.modal-dialog{
    // height: 94%;
    position: fixed;
    margin: 20px;
    right: 0px;
    bottom: 0px;
}
.router-link-active{
    color: white!important;
}
.logos{
    font-family: 'Josefin Sans', sans-serif;
    background-color: rgb(195, 34, 96);
    background-image:url("/public/images/topbanner.png");
    font-size: 24pt;
    padding: 0;
    background-size: cover;
}
.cusdomnavbar{
    background-color: rgba(17,17,17,1);
    width: 100%;
    padding: 1vh 0 0 0;
}
.navbar{
    padding: 0 1vw 0 1vw;
}
.dropdown-menu {
    background-color: rgb(195, 34, 96)!important;
}
.navbar-dark .navbar-toggler{
    border-color: rgba(255,255,255,.6);
    margin: 1vh 0 1vh 0;
}
//Popup
.swal2-title{
    color: black!important;
}
</style>