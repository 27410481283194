
class AuthHeader {
    attachTokenHeader(){
        let token = localStorage.getItem('user');
        if (token){
            return {Authorization: 'Bearer ' + token};
        } else{
            return {};
        }
    }
    //not currently used
    attachTokenAndFileHeader(){
        let token = localStorage.getItem('user');
        if(token){
            return {
                Authorization: 'Bearer ' + token,
                "Content-Type": 'multipart/form-data'
        }
        } else {
            return {};
        }
    }
}


export default new AuthHeader();