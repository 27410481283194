import axios from 'axios';

// nie do końca mangasmodule, bo tak naprawdę do wszystkich kontaktów z backendem jest używane, ale już teraz nie chce mi się zmieniać nazwy gomenasorry

export default axios.create({
    baseURL: "https://hentaihaven.pl/api/",
    headers:{
        'Access-Control-Allow-Origin': '*',
        "Content-type": "application/json",
    },
    credentials: 'same-origin'
});